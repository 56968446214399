class UseTouch {
  constructor({ stopPropagation, vertical } = {}) {
    this.stopPropagation = stopPropagation
    this.vertical = vertical
    this.startX = 0
    this.startY = 0
    this.currentX = 0
    this.currentY = 0
    this.direction = null
    this.moveX = 0,
    this.moveStartX = 0
    this.lastY = 0
    this.moveY = 0
    this.moveStartY = 0
  }

  reset() {
    this.startX = 0
    this.startY = 0
    this.currentX = 0
    this.currentY = 0
    this.direction = null
    this.moveX = 0,
    this.moveStartX = 0
    this.lastY = 0
    this.moveY = 0
    this.moveStartY = 0
  }

  start(e) {
    if (this.vertical) {
      e.preventDefault() // 阻止默认行为
    }
    if (this.stopPropagation) {
      e.stopPropagation()
    }
    this.reset()
    const { pageX, pageY } = this.getTouches(e)
    this.startX = pageX
    this.lastX = pageX
    this.startY = pageY
    this.lastY = pageY
  }

  /**
   * @description 移动
   * @param {Event} e
   * 1.只判断一次滑动方向，如果是水平方向，后续不再判断
   * 2.水平方向移动，阻止默认行为
   * 3.垂直方向移动，不阻止默认行为 (todo: 暂时不阻止，后续根据需求再改)
   * */ 
  move(e) {
    const { pageX, pageY } = this.getTouches(e)
    this.currentX = pageX
    this.currentY = pageY
   
    if (this.direction) {
      if (this.vertical) {
        this.direction = 'vertical' && this.onChangeVertical(e)
      } else {
        this.direction === 'horizontal' && this.onChangeHorizontal(e)
      }
      return 
    }

    const delatX = pageX - this.startX
    const delatY = pageY - this.startY
    const RANGE = 4 // 触发判断的范围

    if (RANGE < Math.abs(delatX) || RANGE < Math.abs(delatY)) {
      const angle = Math.atan(Math.abs(delatY) / Math.abs(delatX))
      const isHorizontal = angle < Math.PI / 4
      if (isHorizontal) {
        this.onChangeHorizontal(e)
        this.direction = 'horizontal'
      } else {
        this.onChangeVertical(e)
        this.direction = 'vertical'
      }
    }
  }

  onChangeVertical(e) {
    if (!this.vertical) return
    e.preventDefault() // 阻止默认行为
    this.moveStartY = this.lastY
    this.moveY = this.currentY - this.lastY // 每次移动距离
    this.lastY = this.currentY
  }

  // 水平方向移动
  onChangeHorizontal(e) {
    if (this.vertical) return
    e.preventDefault() // 阻止默认行为
    this.moveStartX = this.lastX
    this.moveX = this.currentX - this.lastX // 每次移动距离
    this.lastX = this.currentX
  }

  getTouches = (e) => {
    return e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])
  }

  isHorizontal() {
    return this.direction === 'horizontal'
  }

  // 从下到上滑动
  getIsMoveTop() {
    return this.currentY <= this.moveStartY
  }
  
  /**
   * 从右到左滑动 
   * @returns {Boolean} 
  */
  getIsMoveLeft() {
    return this.currentX <= this.moveStartX
  }
}
export default UseTouch
