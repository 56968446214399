/**
 * @description: 倒计时
 * @param {number} time  // 倒计时时间，单位毫秒
 * @param {function} onChange  // 每次倒计时变化时的回调
 * @param {function} onEnd  // 倒计时结束时的回调
 * @returns {object}  // 返回一个对象，包含 start 和 stop 两个方法
 */
function startCountdown({ time, onChange, onEnd, autoplay }) {
  const isRequestAnimationFrame = 'requestAnimationFrame' in window

  let timerId = null
  let isRunning = false

  let endTime = time + Date.now()

  function tick() {
    if (isRunning) {
      const now = Date.now()
      const diff = endTime - now

      if (diff > 0) {
        if (onChange) {
          const timeObj = {
            day: Math.floor(diff / (24 * 3600 * 1000)),
            hour: Math.floor((diff / (3600 * 1000)) % 24),
            minute: Math.floor((diff / (60 * 1000)) % 60),
            second: Math.floor((diff / 1000) % 60),
          }
          onChange(timeObj)
        }
        timerId = window.requestAnimationFrame(tick)
      } else {
        if (!autoplay) {
          isRunning = false
        } else {
          window.cancelAnimationFrame(timerId)
          endTime = time + Date.now()
          timerId = window.requestAnimationFrame(tick)
        }
        onEnd()
      }
    }
  }

  function setTimeoutStart() {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      // onChange()
      onEnd()
      if (autoplay) {
        setTimeoutStart()
      }
    }, time)
  }

  function start({ firstTime } = {}) {
    if (!isRunning) {     
      isRunning = true
      if (isRequestAnimationFrame) {
        if (autoplay) { // 自动播放，更新结束时间
          // firstTime 第一次轮播间隔时长， 接下来的次数按照 time的值去轮播
          firstTime = typeof firstTime === 'number' ? firstTime : time
          endTime = firstTime + Date.now()
        }
        timerId = window.requestAnimationFrame(tick)
      } else {
        setTimeoutStart()
      }
    }
  }

  function stop() {
    // 如果计时器在运行，停止计时器
    if (isRunning) {
      isRunning = false
      if (isRequestAnimationFrame) {
        window.cancelAnimationFrame(timerId)
      } else {
        clearTimeout(timerId)
      }
    }
  }
  
  return {
    start,
    stop
  }
}

// // 调用函数来启动倒计时
// const { start, stop } = startCountdown({
//   time: 1000,
//   autoplay: true,
//   onEnd() {
//     console.log('end')
//   }
// })

// // 调用 start 函数启动计时器
// start()

// // 调用 stop 函数停止计时器
// stop()

export {
  startCountdown
}
