<script lang="jsx">
export default {
  name: 'SSwiperItem',
  props: {
    index: {  // 索引
      type: Number,
      default: -1,
    },
  }, 
  inject: {
    spaceBetween: {
      from: 'spaceBetween',
      default: 0
    },
    direction: {
      from: 'direction',
      default: 'ltr'
    },
    vertical: {
      from: 'vertical',
      default: false
    },
  },
  computed: {
    itemStyle() {
      const style = { }
      if (this.vertical) {
        return style
      }
      if (this.index === 0 || !this.spaceBetween) return style

      style[this.getDirectionLabel(`marginLeft`)] = `${this.spaceBetween}px`

      return style
    },
  },
  methods: {
    getDirectionLabel(property) {
      if (this.direction === 'ltr') {
        return property
      }

      return {
        'marginLeft': 'marginRight'
      }[property]
    },
  },
  render() {
    return (
      <div
        style={ this.itemStyle }
        class={ [{ 's-swiper-item': this.vertical }] }
        vOn:click={ (event) => this.$emit('click', event, this.index) }
      >
        { this.$slots.default }
      </div>
    )
  }
}
</script>


<style lang="less">
.s-swiper-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
</style>
